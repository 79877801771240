import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Parser from "html-react-parser"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import Button from "../../elements/Button"

const Item = styled.div`
  /* flex: 0 0 33%; */
  text-align: center;
  padding: 0 1rem;
  img {
    border-radius: 50%;
  }
`

const WrapperContent = styled.div`
  color: ${props => props.theme.white};
  text-align: center;
  margin-top: 2rem;
`

const Title = styled.p`
  font-family: Rajdhani;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1rem;
`

const WrapperButton = styled.div`
  text-align: center;
  margin-top: 3rem;
`

const CoachingList = ({
  items,
  imageEntrainement,
  imageNutrition,
  imageSuivi,
  buttonText,
  buttonUrl,
  colorSection,
  className,
  padding,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots: true,
        },
      },
    ],
  }

  return (
    <Section color={colorSection} className={className} padding={padding}>
      <Container>
        <Slider {...settings}>
          <Item>
            <Img fixed={imageEntrainement} />
            <WrapperContent>
              <Title>{items[0].titre.text}</Title>
              {Parser(items[0].contenu.html)}
            </WrapperContent>
          </Item>
          <Item>
            <Img fixed={imageNutrition} />
            <WrapperContent>
              <Title>{items[1].titre.text}</Title>
              {Parser(items[1].contenu.html)}
            </WrapperContent>
          </Item>
          <Item>
            <Img fixed={imageSuivi} />
            <WrapperContent>
              <Title>{items[2].titre.text}</Title>
              {Parser(items[2].contenu.html)}
            </WrapperContent>
          </Item>
        </Slider>
        <WrapperButton>
          <Button
            title={buttonText}
            as="link"
            to={buttonUrl}
            colors="primaryWithBg"
          />
        </WrapperButton>
      </Container>
    </Section>
  )
}

export default CoachingList
