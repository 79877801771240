import styled from "styled-components"

const ButtonFilter = styled.button`
  color: ${props => (props.isActive ? props.theme.white : props.theme.ternary)};
  background: ${props =>
    props.isActive ? props.theme.ternary : props.theme.white};
  border: 1px solid ${props => props.theme.ternary};
  font-family: Rajdhani;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: ${props => (props.isActive ? "bold" : "normal")};
  border-radius: 16px;
  /* padding: 3px 10px; */
  padding: 4px 15px;
  margin: ${props => (props.margin ? props.margin : "0.5rem")};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${props => props.theme.white};
    background: ${props => props.theme.ternary};
  }
`

export const ButtonFilterOnBg = styled.button`
  color: ${props => (props.isActive ? props.theme.ternary : props.theme.white)};
  background: ${props =>
    props.isActive ? props.theme.white : props.theme.ternary};
  border: 1px solid ${props => props.theme.white};
  font-family: Rajdhani;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: ${props => (props.isActive ? "bold" : "normal")};
  border-radius: 16px;
  padding: 3px 10px;
  margin: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-width: 100px;
  &:hover {
    color: ${props => props.theme.ternary};
    background: ${props => props.theme.white};
  }
`
export default ButtonFilter
