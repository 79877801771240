import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Commun/Banner"
import LayoutContent from "../components/Commun/LayoutContent"
import FilterButton from "../components/Commun/LayoutContent/FilterButton"
// import YoutubeBloc from "../components/Commun/LayoutContent/Youtube"
import OffreCTA from "../components/Commun/LayoutContent/OffreCTA"
import Social from "../components/Commun/LayoutContent/Social"
import CoachingList from "../components/Commun/CoachingList"
import Contact from "../components/Commun/Contact"

const BlogDetail = ({ data, location }) => {
  const prismicData = getPrismicData(data)

  const [category, setCategory] = useState("")

  const handleClick = value => {
    category === value ? setCategory("") : setCategory(value)
  }

  return (
    <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
      <SEO
        title={prismicData.banner.titre}
        description={prismicData.contenuPrincipal.introduction}
        isBlogPost
        url={location.href}
        image={data.prismicBlogDetail.data.body[1].primary.photo.url}
      />
      <Banner
        title={prismicData.banner.titre}
        imageBanner={data.banner.childImageSharp.fluid}
        imageHeight="350px"
        imageMobileHeight="350px"
        isfirstBlock
        marginBottom="0"
        objectPosition="center 80% !important"
      />
      <LayoutContent
        contenu={prismicData.contenuPrincipal}
        noFilter
        buttonText="Prendre RDV"
        buttonUrl="/contact"
      >
        <FilterButton
          items={prismicData.listeArticles2.items}
          itemSelected={category}
          handleClick={handleClick}
          isCategoryLinked
          articleCategory={prismicData.contenuPrincipal.categorie}
        />
        <OffreCTA
          titre="Mes Coachings"
          text="Découvrez toutes mes prestations de coaching sportif personnalisé pour homme et pour femme à Nantes et à distance"
          buttonText="En savoir plus"
          buttonUrl="/coaching-sportif-personnalise"
        />
        {/* <YoutubeBloc /> */}
        <Social />
      </LayoutContent>
      <CoachingList
        items={prismicData.coaching.items}
        buttonText="Voir mes coachings"
        buttonUrl="/coaching-sportif-personnalise"
        colorSection="ternary"
        padding="50px 0"
        imageEntrainement={data.coachingListEntrainement.childImageSharp.fixed}
        imageNutrition={data.coachingListNutrition.childImageSharp.fixed}
        imageSuivi={data.coachingListSuivi.childImageSharp.fixed}
      />
      <Contact />
    </Layout>
  )
}

export default BlogDetail

export const query = graphql`
  query($uid: String!) {
    prismicBlogDetail(uid: { eq: $uid }) {
      uid
      data {
        body {
          ... on PrismicBlogDetailBodyBanniere {
            primary {
              contenu {
                html
                text
              }
            }
          }
          ... on PrismicBlogDetailBodyContenuPrincipal {
            primary {
              contenu {
                html
              }
              date {
                html
                text
              }
              photo {
                url
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              titre {
                text
              }
              categorie {
                text
              }
              introduction {
                text
              }
              # video {
              #   embed_url
              #   html
              # }
            }
          }
        }
      }
    }
    allPrismicBlogDetail {
      edges {
        node {
          uid
          data {
            body {
              ... on PrismicBlogDetailBodyContenuPrincipal {
                primary {
                  categorie {
                    text
                  }
                  introduction {
                    text
                  }
                  titre {
                    text
                  }
                  photo {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicAccueilBodyCoaching {
      items {
        contenu {
          html
          text
        }
        titre {
          html
          text
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    banner: file(relativePath: { eq: "articles.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coachingListEntrainement: file(
      relativePath: { eq: "programme-entrainement.jpeg" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListNutrition: file(relativePath: { eq: "nutrition.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListSuivi: file(relativePath: { eq: "suivi-perso.jpeg" }) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

function getPrismicData(data) {
  return {
    banner: {
      titre: data.prismicBlogDetail.data.body[0].primary.contenu.text,
    },
    listeArticles2: {
      items: data.allPrismicBlogDetail.edges,
    },
    contenuPrincipal: {
      contenu: data.prismicBlogDetail.data.body[1].primary.contenu.html,
      date: data.prismicBlogDetail.data.body[1].primary.date,
      image: data.prismicBlogDetail.data.body[1].primary.photo.fluid,
      titre: data.prismicBlogDetail.data.body[1].primary.titre.text,
      introduction:
        data.prismicBlogDetail.data.body[1].primary.introduction.text,
      categorie: data.prismicBlogDetail.data.body[1].primary.categorie.text,
      // video: data.prismicBlogDetail.data.body[1].primary.video.html,
    },
    coaching: {
      items: data.prismicAccueilBodyCoaching.items,
    },
  }
}
