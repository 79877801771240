import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"
import Button from "../../elements/Button"

const Wrapper = styled.div`
  margin-left: 0;
  background-color: white;
  border-radius: ${globalVariables.borderRadius};
  padding: 2rem;
  height: fit-content;
  margin-top: 2rem;
  a span.btn-text {
    font-size: 16px;
  }
`

const Title = styled.p`
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.headerColor};
`
const Text = styled.p`
  color: ${props => props.theme.bodyColor};
  padding: 1rem 0;
  font-size: 14px;
`

const OffreCTA = ({ titre, text, buttonText, buttonUrl }) => {
  return (
    <Wrapper>
      <Title>{titre}</Title>
      <Text>{text}</Text>
      {buttonText  && buttonUrl && <Button
        title={buttonText}
        as="link"
        to={buttonUrl}
        colors="secondaryWithBg"
      />}
    </Wrapper>
  )
}

export default OffreCTA
