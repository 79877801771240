import React from "react"

import Section from "../../elements/Section/index"
import Container from "../../elements/Container/index"
import ContactForm from "./ContactForm"

const Contact = () => {
  return (
    <Section color="greyLighter">
      <Container>
        <ContactForm />
      </Container>
    </Section>
  )
}

export default Contact
