import React from "react"
import styled from "styled-components"
import { Facebook } from "styled-icons/boxicons-logos/Facebook"
import { Instagram } from "styled-icons/boxicons-logos/Instagram"
import { Youtube } from "styled-icons/fa-brands/Youtube"

import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  margin-left: 0;
  background-color: white;
  border-radius: ${globalVariables.borderRadius};
  padding: 2rem;
  height: fit-content;
  margin-top: 2rem;
  a span.btn-text {
    font-size: 16px;
  }
`

const Title = styled.p`
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.headerColor};
`

const IconList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`

const IconWrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.primary};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  &:hover {
    background: ${props => props.theme.secondary};
  }
`

const FacebookStyled = styled(Facebook)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
`
const InstagramStyled = styled(Instagram)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
`
const YoutubeStyled = styled(Youtube)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
`

const Social = () => {
  return (
    <Wrapper>
      <Title>Suivez-moi</Title>
      <IconList>
        <IconWrapper
          href="https://www.facebook.com/kevcoachingpro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookStyled />
        </IconWrapper>
        <IconWrapper
          href="https://www.instagram.com/kevcoachingpro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramStyled />
        </IconWrapper>
        {/* <IconWrapper
          href="https://www.youtube.com/channel/UCgWw8Ln8TD7_LNTJmw1LH4g/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeStyled />
        </IconWrapper> */}
      </IconList>
    </Wrapper>
  )
}

export default Social
