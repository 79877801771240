import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Parser from "html-react-parser"

import Section from "../../elements/Section"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"
import ButtonFilter from "../../elements/Button/ButtonFilter"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    padding: 0 2rem;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 0 4rem;
  }
`

const Sidebar = styled.div`
  order: 2;

  /* @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 1 22%;
    order: 1;
  } */

  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 1 35%;
    order: 1;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 1 30%;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    flex: 0 1 22%;
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    flex: 0 1 20%;
  }
`

const Content = styled.div`
  flex: 1 1 78%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  order: 1;
  @media (max-width: ${globalVariables.maxWidth}) {
    order: 1;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding-left: 2rem;
    margin-top: 0;
    order: 2;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding-left: 4rem;
  }

  @media (min-width: ${globalVariables.bigDesktop}) {
    flex: 0 1 80%;
    padding-left: 6rem;
    max-width: 1300px;
  }
`
const WrapperImg = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    height: 300px;
  }
  img {
    object-position: ${props =>
      props.objectPosition ? props.objectPosition : null};
  }
`
const WrapperBody = styled.div`
  margin-bottom: 2rem;
  background: white;
  padding: 35px 20px;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 40px;
  }
`

const WrapperButton = styled.div`
  margin: 3rem 0 1rem;
  .snipcart-add-item {
    min-height: 46px;
  }
`

const Title = styled.h2`
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.headerColor};
  margin-top: ${props => (props.noFilter ? "0" : "1.5rem")};
  margin-bottom: 1rem;
`

const WrapperText = styled.div`
  p,
  ul,
  li,
  span {
    text-align: justify;
    color: ${props => props.theme.bodyColor};
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }
  a {
    color: ${props => props.theme.secondary};
    transition: color 0.1s ease-in-out;
    &:hover {
      color: ${props => props.theme.primary};
    }
  }
  .block-img img {
    margin: 2rem 0;
  }
`

const Date = styled.p`
  text-align: justify;
  color: ${props => props.theme.bodyColor};
  line-height: 1.4;
  margin-bottom: 1rem;
  margin-top: -1rem;
`

const VideoWrapper = styled.div`
  height: 300px;
  iframe {
    margin-bottom: 0;
    border: none;
    height: 100%;
    width: 100%;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    height: 400px;
  }
`

const Prix = styled.span`
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.white};
  position: absolute;
  top: ${props => (props.isPromoActive ? "30%" : "10%")};
  left: 0;
  font-size: 20px;
  padding: 5px 10px;
  font-family: Rajdhani;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 26px;
  }
  &.promo-active {
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      height: 1px;
      background: white;
    }
  }
`

const Promo = styled.span`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  position: absolute;
  top: 10%;
  left: 0;
  font-size: 20px;
  padding: 5px 10px;
  font-family: Rajdhani;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 26px;
  }
`

const Message = styled.p`
  position: absolute;
  top: 10%;
  left: 0;
  background-color: ${props => props.theme.primary};
  padding: 5px 17px;
  color: white;
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 18px;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 26px;
  }
`

const LayoutContent = ({
  contenu,
  noButton,
  contenuPrincipalHomme,
  contenuPrincipalFemme,
  noFilter,
  handleClick,
  gender,
  objectPosition,
  buttonText,
  buttonUrl,
  children,
  titreSnipcart,
  descriptionSnipcart,
  prixSnipcart,
  idSnipcart,
  urlSnipcart,
  statutSnipcart,
  promotion,
  statutEbook,
  idFileEbook
}) => {
  const content = noFilter
    ? contenu
    : gender === "homme"
    ? contenuPrincipalHomme
    : contenuPrincipalFemme

  return (
    <Section color="greyLighter">
      <Wrapper>
        <Sidebar>{children}</Sidebar>
        <Content>
          {/* {content.video ? (
            <VideoWrapper>{Parser(content.video)}</VideoWrapper>
          ) : ( */}
          <WrapperImg objectPosition={objectPosition}>
            <Img fluid={content.image} />
            {promotion && promotion.active === "oui" && (
              <Promo>
                {promotion.label} {promotion.prix} €
              </Promo>
            )}
            {prixSnipcart && (
              <Prix
                isPromoActive={promotion.active === "oui"}
                className={promotion.active === "oui" ? "promo-active" : null}
              >
                {prixSnipcart} €
              </Prix>
            )}
            {statutEbook && statutEbook === "cacher" && (
              <Message>Arrive bientôt</Message>
            )}
          </WrapperImg>
          {/* )} */}

          <WrapperBody>
            {!noFilter && (
              <React.Fragment>
                <ButtonFilter
                  onClick={() => handleClick("homme")}
                  isActive={gender === "homme"}
                  margin="0 1rem 0 0"
                >
                  Pour Homme
                </ButtonFilter>
                <ButtonFilter
                  onClick={() => handleClick("femme")}
                  isActive={gender === "femme"}
                  margin="0"
                >
                  Pour Femme
                </ButtonFilter>
              </React.Fragment>
            )}

            <Title noFilter={noFilter}>{content.titre}</Title>
            {content.date && <Date>{content.date.text}</Date>}
            <WrapperText>{Parser(content.contenu)}</WrapperText>
            {!noButton && (
              <WrapperButton>
                {statutSnipcart === "publier" ? (
                  <Button
                    className="snipcart-add-item"
                    data-item-id={idSnipcart}
                    data-item-name={titreSnipcart}
                    data-item-price={prixSnipcart}
                    data-item-url={
                      "https://www.kevcoachingpro.fr/e-books/" +
                      urlSnipcart
                    }
                    data-item-description={descriptionSnipcart}
                    title={
                      promotion.active === "oui"
                        ? `Acheter cet e-book (${promotion.prix} € au lieu de ${prixSnipcart} €)`
                        : `Acheter cet e-book (${prixSnipcart} €)`
                    }
                    colors="primaryWithBg"
                    // data-item-file-guid={idFileEbook}
                  />
                ) : null}

              </WrapperButton>
            )}
          </WrapperBody>
        </Content>
      </Wrapper>
    </Section>
  )
}

export default LayoutContent
